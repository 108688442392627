import React, { useEffect } from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { useDispatch, useSelector } from 'react-redux';
import { searchContent } from '@plone/volto/actions';
import { flattenToAppURL } from '@plone/volto/helpers';
import PropTypes from 'prop-types';
import LightGallery from 'lightgallery/react';
import { Image } from 'semantic-ui-react';
import lgZoom from 'lightgallery/plugins/zoom';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';

const LightGalleryView = (props) => {
  const { data, id, content } = props;
  const dispatch = useDispatch();

  const subrequestID = content?.UID ? `${content?.UID}-${id}` : id;
  const images = useSelector((state) => state?.search?.subrequests?.[subrequestID]?.items,);

  const contentData = data.galleryurl;
  const colNumb = data.colNumb;

  //to sort by title insert sortable_title
  var sortOn = 'None'
  var sortOrder = 'ascending';
  if (data.sortOn != 0) {
    sortOn = data.sortOn
  }
  if (data.sortOrder) {
    sortOrder = 'descending';
  }

  useEffect(() => {
    dispatch(
      searchContent(
        contentData,
        {
          portal_type: ['Image'],
          b_size: 1000,
          sort_on: sortOn,
          sort_order: sortOrder,
        },
        subrequestID,
      ),
    );
  }, []);

  return (
    <>
      <div className="App">
        {data.title?.length > 0 && (
          <h1>{data.title}</h1>
        )}
        {images?.length > 0 && (
          <ImageList className="lightgalleryUl" variant="masonry" cols={colNumb} gap={12}>
            <LightGallery plugins={[lgZoom]} mode="lg-fade" licenseKey="7A2B0035-CC15400F-9033398C-0D4C7A80" elementClassNames="lightgallery">
              {images?.map((item, index) => (
                <ImageListItem key={index} className="gallery-item"
                  href={flattenToAppURL(`${item["@id"]}/@@images/${item?.image_field}`,)}
                  data-sub-html={item?.description}
                  data-src={flattenToAppURL(`${item["@id"]}/@@images/${item?.image_field}`,)}>
                  <Image src={`${item["@id"]}/@@images/${item?.image_field}/teaser`} className="img-responsive" alt={item?.description} loading="lazy" />
                </ImageListItem>
              ))}
            </LightGallery>
          </ImageList>
        )}
      </div>
    </>
  );
};

LightGalleryView.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any),
};

export default LightGalleryView;