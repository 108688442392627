import React from 'react';
import { List, Segment } from 'semantic-ui-react';
import { FormattedMessage, injectIntl } from 'react-intl';


const Footer = ({ intl }) => {
  const currentYear = new Date().getFullYear();

  return (
    <Segment role="contentinfo" padded id="footer">
      <div className="footerTop">

        <div className='logoWhiteDiv'>
          <img src='../Logo_White.svg' alt='Logo White' className='logoWhite' />
        </div>

        <div>
          <List className="footerList">
            <div role="listitem" className="item footerName">
              <p className="item">
                <FormattedMessage
                  id="Footer-Name"
                  defaultMessage="Thuner Management AG"
                />
              </p>
            </div>
            <div role="listitem" className="item">
              <p className="item">
                <FormattedMessage
                  id="Footer-Description"
                  defaultMessage="Bauunternehmung"
                />
              </p>
            </div>
            <div role="listitem" className="item">
              <p className="item">
                <FormattedMessage
                  id="Footer-Adress"
                  defaultMessage="Dorfstrasse 2"
                />
              </p>
            </div>
            <div role="listitem" className="item">
              <p className="item">
                <FormattedMessage
                  id='Footer-Plz-Ort'
                  defaultMessage="3555 Trubschachen"
                />
              </p>
            </div>
          </List>
        </div>
        
        <div>
          <List className="footerList">
            <div role="listitem" className="item">
              <i className="ri-phone-line"></i>
              <a className="item" href="tel:034 495 59 81">
                <FormattedMessage
                  id='Footer-Telefon'
                  defaultMessage="034 495 59 81"
                />
              </a>
            </div>
            <div role="listitem" className="item">
              <i className="ri-phone-line"></i>
              <a className="item" href="tel:079 290 87 31">
                <FormattedMessage
                  id='Footer-Mobile'
                  defaultMessage="079 290 87 31"
                />
              </a>
            </div>
            <div role="listitem" className="item">
              <i className="ri-printer-line"></i>
              <a className="item" href="fax:034 495 64 82">
                <FormattedMessage
                  id='Footer-Fax'
                  defaultMessage="034 495 64 82"
                />
              </a>
            </div>
            <div role="listitem" className="item">
              <i className="ri-mail-line"></i>
              <a className="item" href="mailto:info@thunermanagement.ch">
                <FormattedMessage
                  id='Footer-Email'
                  defaultMessage="info@thunermanagement.ch"
                />
              </a>
            </div>
          </List>
        </div>
      </div>
      <hr/>
      <div className='footerBottom'>
        <div>
          <List className="footerList">
            <div role="listitem" className="item">
              <a className="item" href="./impressum">
                <FormattedMessage
                  id='Footer-Imprint'
                  defaultMessage="Impressum"
                />
              </a>
            </div>
            <div role="listitem" className="item">
              <a className="item" href="./datenschutz">
                <FormattedMessage
                  id='Footer-Privacy'
                  defaultMessage="Datenschutzerklärung"
                />
              </a>
            </div>
            <div role="listitem" className="item">
              <i className="ri-copyright-line"></i>
              {currentYear}&nbsp;
              <FormattedMessage
                id='Footer-Copyright'
                defaultMessage="Thuner Management AG"
              />
            </div>
          </List>
        </div>
      </div>
    </Segment>
  );
};

export default injectIntl(Footer);